import React from 'react'
import {Link} from 'react-router-dom'

export default function Header() {
  return (
    <header style={headerStyle}>
      <h1>Tim Lucca</h1>
      <Link style={linkStyle} to='/'>Home</Link> |
      <Link style={linkStyle} to='/projects/'> Projects</Link>
    </header>
  )
}

const headerStyle = {
  background: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '2rem'
}

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  padding: '1rem'
}